import {DiscoveryApi, IdentityApi} from "@backstage/core-plugin-api";
import {App, CreateAppParams, Release, ReleaseHistory, ReleaseManagerApi} from "./types";

export class ReleaseManagerApiClient implements ReleaseManagerApi {
  private readonly discoveryApi: DiscoveryApi
  private readonly identityApi: IdentityApi
  constructor(options: {
    discoveryApi: DiscoveryApi,
    identityApi: IdentityApi
  }) {
    this.discoveryApi = options.discoveryApi
    this.identityApi = options.identityApi
  }
  async getHealth(): Promise<{ status: string }> {
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/health`)
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return await response.json()
  }

  async getApps(): Promise<App[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/releases`, {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
      }
    })
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return await response.json()
  }

  async createApp(app: CreateAppParams): Promise<void> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const body = JSON.stringify(app)
    const response = await fetch(`${baseUrl}/apps`, {
      method: 'POST',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
        'Content-Type': 'application/json'
      },
      body
    })
    if (!response.ok) {
      throw new Error(response.statusText)
    }
  }

  async getRelease(appId: number, version: string): Promise<Release> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const response = await fetch(`${baseUrl}/apps/${appId}/releases/${version}`, {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
      }
    })
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return await response.json()
  }

  async queryCompletedReleases(deployedDateStart: string, deployedDateEnd: string | undefined): Promise<ReleaseHistory[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const baseUrl =   await this.discoveryApi.getBaseUrl('release_manager')
    const url = new URL(`${baseUrl}/release/completed`)
    url.searchParams.set('deployedDateStart', deployedDateStart)
    if (deployedDateEnd) {
      url.searchParams.set('deployedDateEnd', deployedDateEnd)
    }
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        ...(idToken && {Authorization: `Bearer ${idToken}`}),
      }
    })
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return await response.json()
  }
}

export default ReleaseManagerApiClient
